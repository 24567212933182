body {
    font-family: 'Segoe UI', Arial, sans-serif;
    color: #333;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 1000px;
    margin: 20px auto 20px auto;
    padding: 20px;

    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1,
h2 {
    color: #005ea6;
}

/* form {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"],
input[type="password"],
textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    margin: 5px 0 15px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    padding: 10px 20px 10px 20px;
    background-color: #0078d7;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    /*width: 100%;*/
}

button:hover {
    background-color: #005ea6;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    padding: 10px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #eee;
}

li:last-child {
    border-bottom: none;
}

.text-center {
    text-align: center;
}

@media (min-width: 768px) {
    .container {
        max-width: 1200px;
        padding: 20px;
    }

    h1,
    h2 {
        font-size: 1.5rem;
    }
}

li {
    padding: 10px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s;
    cursor: pointer;
}

li:hover {
    background-color: #e9e9e9;
}

li:active {
    background-color: #ddd;
}

.create-job-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    /*margin: 15px 0;*/
    border: none;
    border-radius: 5px;
    cursor: pointer;
    max-width: 300px;
}

.create-job-button:hover {
    background-color: #45a049;
}

/* COMPONENT LIBRARY */

button,
input,
textarea {
    font-family: inherit;
    font-size: 16px;
}

button {
    cursor: pointer;
}

/* Navigation Bar */
nav {
    background-color: #0078d7;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-around;
}

nav button {
    background-color: #005ea6;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    text-transform: uppercase;
}

nav button:hover {
    background-color: #00396b;
}

/* Section Titles */
h2 {
    color: #005ea6;
    margin-bottom: 15px;
}

/* Large Button Styles for Inspected and Quality components */
.large-button {
    background-color: #e1e1e1;
    color: #333;
    border: none;
    padding: 20px;
    margin: 10px;
    width: 100px;
    /* Large enough for easy tapping on mobile */
    height: 100px;
    /* Large enough for easy tapping on mobile */
    border-radius: 10px;
    text-align: center;
    vertical-align: middle;
    line-height: 90px;
    /* Adjust to vertically center the text */
    display: inline-block;
}

.large-button.active {
    background-color: #0078d7;
    color: white;
}

/* Comments Section */
.comments {
    margin: 10px 0;
}

.comments textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
}

/* Photo List */
.photo-list {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.photo-item {
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
}

.photo-item img {
    width: 100%;
    height: auto;
}

.photo-item button {
    display: block;
    width: 100%;
    padding: 5px;
    background-color: #d9534f;
    color: white;
    border: none;
    border-radius: 0 0 5px 5px;
}

.photo-item button:hover {
    background-color: #c9302c;
}

.section-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.quality-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
}

.quality-button {
    flex: 1 1 20%;
    max-width: calc(25% - 10px);
    padding: 20px;
    margin: 5px 0;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: #f8f8f8;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;
    color: #333;
}

.quality-button:hover {
    background-color: #e0e0e0;
}

.quality-button.selected {
    background-color: #d0d0d0;
}

.comments-container {
    margin: 20px 0;
}

.comments-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
}

.photo-list {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    padding: 10px;
}

.photo-controls {
    /*display: flex;
    justify-content: space-around;*/
    margin-top: 10px;
}

.upload-button {
    display: inline;
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.photo-list {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    padding: 10px;
}

.photo {
    position: relative;
    flex: 0 0 auto;
    /* Do not grow or shrink, but allow basis to be auto */
}

.photo-image {
    max-height: 200px;
    /* Adjust based on your needs */
    width: auto;
    display: block;
}

.delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px 10px;
}

.camera-capture {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 9999;
    /* Ensure it covers other content */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.camera-video {
    width: 100%;
    height: auto;
    /* Maintain aspect ratio */
}

.camera-controls {
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: space-around;
    width: 100%;
}